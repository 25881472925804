import "./../auditing/auditing.scss";
import NavbarVendas from "../../components/navbar/navbarVendas";
import { Divider, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { DrawerContext } from "../../contexts/drawerContext";
import { StyledTabs, StyledButtonPrimary } from "../../components/custom-ui";
import { Avatar, Empty } from "antd";
import DefaultAvatar from "../../assets/images/default-avatar.png";
import { axiosInstance } from "../../services/axios";
import { formatDistance } from "date-fns";

const ActivitiesTab1 = ({ subtabs, onChangeFilterDayTabs }) => {
  return (
    <div className="auditing__body__card auditing__body__card--activities">
      <h5 className="auditing__body__card--activities__title">Atividades</h5>
      <StyledTabs
        type="card"
        size="large"
        items={subtabs}
        onChange={onChangeFilterDayTabs}
      />
    </div>
  );
};

const FilterByDateTab = ({ auditingData = [] }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxHeight: 422,
        overflow: "auto",
      }}
    >
      {auditingData.length === 0 ? (
        <Empty description="Não há ações para listar"></Empty>
      ) : (
        <>
          {auditingData.map((action, index) => (
            <div key={index}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  src={
                    <img
                      src={DefaultAvatar}
                      alt="avatar"
                      style={{ width: 32, height: 32 }}
                    />
                  }
                  style={{ marginRight: 16 }}
                />
                <div>
                  {getActionText(action)}
                  <p
                    style={{
                      color: "rgba(0, 0, 0, 0.45)",
                      fontSize: 14,
                      margin: 0,
                    }}
                  >
                    {formatDistance(new Date(action.created_at), new Date(), {
                      addSuffix: true,
                    })}
                  </p>
                </div>
              </div>
              <Divider />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

const getActionText = (action) => {
  const actionTextStrategy = {
    PURCHASE_ORDER: {
      CREATE: (
        <p style={{ margin: 0 }}>
          {`${action.created_by_name} (${action.created_by_role})`} iniciou a{" "}
          <StyledButtonPrimary style={{ padding: 0 }} type="link">
            ordem de compras.
          </StyledButtonPrimary>
        </p>
      ),
      UPDATE: (
        <p style={{ margin: 0 }}>
          {`${action.created_by_name} (${action.created_by_role})`} editou{" "}
          <StyledButtonPrimary style={{ padding: 0 }} type="link">
            ordem de compras.
          </StyledButtonPrimary>
        </p>
      ),
    },
    PACKING: {
      CREATE: (
        <p style={{ margin: 0 }}>
          {`${action.created_by_name} (${action.created_by_role})`} preencheu a
          planilha de{" "}
          <StyledButtonPrimary style={{ padding: 0 }} type="link">
            packing.
          </StyledButtonPrimary>
        </p>
      ),
    },
    PACKING_DATA: {
      UPDATE: (
        <p style={{ margin: 0 }}>
          {`${action.created_by_name} (${action.created_by_role})`} editou um{" "}
          <StyledButtonPrimary style={{ padding: 0 }} type="link" href="./packings">
            packing.
          </StyledButtonPrimary>
        </p>
      ),
    },
  };

  if (
    actionTextStrategy[action.action_type] &&
    actionTextStrategy[action.action_type][action.action]
  )
    return actionTextStrategy[action.action_type][action.action];

  return "";
};

const AuditingPage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { isVisible } = useContext(DrawerContext);
  const [auditingData, setAuditingData] = useState([]);

  const getAuditingData = async (period) => {
    try {
      const response = await axiosInstance(
        `/auditing?page=1&pageSize=20&filterBy=${period}`
      );

      setAuditingData(response.data.data);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error ao buscar ordens de compra",
      });
    } finally {
    }
  };

  const tabs = [
    {
      label: "Histórico de atividades",
      children: (
        <ActivitiesTab1
          onChangeFilterDayTabs={getAuditingData}
          subtabs={[
            {
              label: "Dia",
              children: (
                <FilterByDateTab period="day" auditingData={auditingData} />
              ),
              key: "day",
            },
            {
              label: "Semana",
              children: (
                <FilterByDateTab period="week" auditingData={auditingData} />
              ),
              key: "week",
            },
            {
              label: "Mês",
              children: (
                <FilterByDateTab period="month" auditingData={auditingData} />
              ),
              key: "month",
            },
          ]}
        />
      ),
      key: "1",
    },
  ];

  useEffect(() => {
    getAuditingData("day");
  }, []);

  return (
    <>
      <div className="auditing" style={{ marginLeft: isVisible ? 272 : 0 }}>
        <NavbarVendas />
        {contextHolder}
        <div className="auditing__body">
          <div className="auditing__body__card">
            <h3 className="auditing__body__card__title">
              Auditoria do processo
            </h3>
            <Divider />
            <div className="auditing__body__card__sub-container">
              <StyledTabs items={tabs} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuditingPage;
