import styled from "styled-components";
import { Collapse, Drawer as AntdDrawer } from "antd";
import { AuditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { isRouteActive } from "./../../services/navbar.service";
import { UserContext } from "../../contexts/userContext";
import { useContext } from "react";
import CompalLogo from "../../assets/images/compal_logo.png";

const StyledDrawer = styled(AntdDrawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-collapse-arrow[aria-label="expanded"] {
    rotate: 180deg;
  }

  .ant-collapse-arrow[aria-label="collapsed"] {
    rotate: 90deg;
  }
`;

const Drawer = ({ isDrawerVisible, items }) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  return (
    <StyledDrawer
      open={isDrawerVisible}
      mask={false}
      closable={false}
      placement="left"
      width={256}
    >
      <div style={{ textAlign: "center" }}>
        <img src={CompalLogo} alt="Logo" style={{ width: 200, height: 140 }} /> 
      </div>
      <div className="drawer__menu">
        <StyledCollapse
          defaultActiveKey={["1"]}
          ghost
          items={items}
          expandIconPosition="end"
        />
        {user.role === "admin" && (
          <div
            className={`drawer__menu__item ${
              isRouteActive("auditing") ? "active" : ""
            }`}
            style={{ marginTop: 12, padding: "12px 16px" }}
            onClick={() => navigate("/auditing")}
          >
            <AuditOutlined className="drawer__menu__item__icon" />
            Auditoria
          </div>
        )}
      </div>
    </StyledDrawer>
  );
};

export default Drawer;
