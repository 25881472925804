import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginPage from "./pages/login/login";
import OrdersPage from "./pages/orders/orders";
import AuditingPage from "./pages/auditing/auditing";
import "antd/dist/reset.css";
import "./index.css";
import { ConfigProvider } from "antd";
import ptBR from "antd/lib/locale/pt_BR";
import { DrawerContextProvider } from "./contexts/drawerContext";
import "./services/date-fns";
import PackingsPage from "./pages/packings/packings";
import PackingPage from "./pages/packing/packing";
import { UserContextProvider } from "./contexts/userContext";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/orders",
    element: <OrdersPage />,
  },
  {
    path: "/auditing",
    element: <AuditingPage />,
  },
  {
    path: "/packings",
    element: <PackingsPage />,
  },
  {
    path: "/packings/:packingId",
    element: <PackingPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "rgba(56, 158, 13, 1)",
        },
      }}
      locale={ptBR}
    >
      <UserContextProvider>
        <DrawerContextProvider>
          <RouterProvider router={router} />
        </DrawerContextProvider>
      </UserContextProvider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
